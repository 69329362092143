<template>
    <div>
        <b-modal 
        :id="$route.name + 'CreateModal'" 
        title="Создать тип тарифа" 
        size="lg"
        hide-footer 
        no-enforce-focus 
        :no-close-on-backdrop="true"
        @hidden="clearData">
            <b-row>
                <b-col cols="6">
                    <fieldset class="border p-1">
                        <legend class="mb-0" style="width: fit-content">Информация</legend>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Подразделение</label>
                                <v-select
                                v-model="tariffType.division_id"
                                :options="divisions"
                                :reduce="division => division.id"
                                label="name"
                                placeholder="Подразделение"
                                />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Тип тарифа</label>
                                <b-input v-model="tariffType.name" placeholder="Название" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Категория тарифы</label>
                                <v-select
                                v-model="tariffType.category_tariff_id"
                                :options="category_tariffs"
                                :reduce="category => category.id"
                                label="name"
                                placeholder="Категория тарифы"
                                class="select-size-md"
                                />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Код</label>
                                <b-input v-model="tariffType.code" placeholder="Введите код" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Категория авто</label>
                                <v-select
                                v-model="tariffType.category_car_id"
                                :options="carCategories"
                                :reduce="category => category.id"
                                label="name"
                                placeholder="Категория авто"
                                class="select-size-md"
                                />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col class="p-0 pl-1 pr-1" cols="12">
                                <label class="mb-0">Надбавки</label>
                                <v-select
                                v-model="tariffType.allowances"
                                :options="noneLinkedAllowances"
                                label="name"
                                :closeOnSelect="false"
                                multiple
                                placeholder="Надбавки"
                                class="select-size-md"
                                />
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Описание в приложении</label>
                                <b-form-textarea
                                v-model="tariffType.description_app"
                                placeholder="Описание в приложении"
                                rows="3"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Подсказка для клиента</label>
                                <b-form-textarea
                                v-model="tariffType.client_hint"
                                placeholder="Подсказка для клиента"
                                rows="3"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <label class="mb-0">Дополнительная информация</label>
                                <b-form-textarea
                                v-model="tariffType.dop_info"
                                placeholder="Дополнительная информация"
                                rows="3"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                               <b-form-checkbox :value="1" :unchecked-value="0" v-model="tariffType.site_show" class="custom-control-primary">
                                Показать на сайте
                                </b-form-checkbox>  
                            </b-col>
                            <b-col>
                               <b-form-checkbox :value="1" :unchecked-value="0" v-model="tariffType.app_show" class="custom-control-primary">
                                Показать в приложении
                                </b-form-checkbox>  
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-1" cols="12">
                                <img class="mw-100" v-if="iconSrc" :src="iconSrc">
                                <label class="mb-0">Иконка</label>
                                <b-form-file
                                v-model="tariffType.icon"
                                @change="uploadFile($event, 'iconSrc')"
                                browse-text="Добавить"
                                accept=".png"
                                placeholder="Файл не выбран"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-1" cols="12">
                                <img class="mw-100" v-if="imageSrc" :src="imageSrc">
                                <label class="mb-0">Изображение</label>
                                <b-form-file
                                v-model="tariffType.image"
                                @change="uploadFile($event, 'imageSrc')"
                                browse-text="Добавить"
                                accept=".png"
                                placeholder="Файл не выбран"/>
                            </b-col>

                        </b-row>
                    </fieldset>
                </b-col>
                <b-col cols="6">
                    <fieldset class="p-1 border">
                        <legend class="mb-0" style="width: fit-content">
                            Надбавки
                        </legend>
                        <b-row v-for="allowance in tariffType.allowances" :key="'sorted-allowance-' + allowance.id">
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Надбавка</label>
                                    <b-input-group style="border: 1px solid #e7e3e3; padding: 7px 12px; border-radius: 5px;">
                                        <span>{{ allowance.name }}</span>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Номер сортировки</label>
                                    <b-input-group>
                                        <b-form-input v-model="allowance.sort" placeholder="№"/>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </fieldset>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12"><hr></b-col>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    @click="creatTariffType"
                    :disabled="submitButtonDisabled"
                    class="col-md-5"
                    size="sm"
                    variant="primary">
                        Добавить
                    </b-button>
                    <b-button class="col-md-5" size="sm" variant="secondary" @click="$bvModal.hide($route.name + 'CreateModal')">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
    BRow, BCol,
} from 'bootstrap-vue'
export default {
    components: {
        ToastificationContent,
        vSelect,
        BRow,
        BCol,
    },
    props: ['allowances', 'divisions', 'carCategories', 'category_tariffs'],
    data(){
        return {
            iconSrc: '',
            imageSrc: '',
            tariffType: {
                allowances: [],
                app_show: 1,
                category_car_id: null,
                category_tariff_id: null,
                client_hint: '',
                code: "",
                description_app: '',
                dop_info: '',
                division_id: null,
                icon: null,
                image: null,
                name: '',
                site_show: 1,
            },
            submitButtonDisabled: false,
        }
    },
    methods:{
        clearData() {
            this.tariffType = {
                allowances: [],
                app_show: true,
                category_car_id: null,
                client_hint: null,
                code: null,
                description_app: '',
                dop_info: null,
                division_id: null,
                icon: null,
                image: null,
                name: null,
                site_show: true,
            }
            this.tariffType.allowances = [];
            this.tariffType.allowances.length = 0
        },
        creatTariffType() {
            let structuredAllowances = this.tariffType.allowances.map((allowance) => {
                return { allowance_id: allowance.id, sort: allowance.sort}
            })
            const data = JSON.parse(JSON.stringify(this.tariffType))
            data.allowances = JSON.stringify(structuredAllowances)
            let formData = new FormData()
            formData.append('icon', this.tariffType.icon)
            formData.append('image', this.tariffType.image)
            this.submitButtonDisabled = true
            this.$http.post('tariff/types', formData, {
                params: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                    title: 'Успех!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: res.data.message,
                    },
                })
                this.$bvModal.hide(this.$route.name + 'CreateModal')
                this.$emit('refresh')
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        uploadFile($event, target){
            let file = $event.target.files[0];
            this[target] = URL.createObjectURL(file);
        },
    },
    computed: {
        noneLinkedAllowances() {
            return JSON.parse(JSON.stringify(this.allowances))
        } 
    }
}
</script>