<template>
<div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
        <table-tariff-type :items="items" @editForm="editForm" @creatTemplate="creatTemplate"/>
    </div>

    <modal-tariff-type :allowances="allowances" :divisions="divisions" :carCategories="carCategories"
    :category_tariffs="category_tariffs"
    @refresh="refresh"/>
    <modal-tariff-type-edit
    @refresh="refresh"
    :allowances="allowances"
    :divisions="divisions"
    :carCategories="carCategories"
    :category_tariffs="category_tariffs"
    :idEditTemplate="idEditTemplate"/>
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"/>
</div>
</template>
<script>
import TableTariffType from '@/views/component/Table/tableTariffType.vue'
import ModalTariffType from '@/views/component/Modal/ModalTariffType/ModalTariffType.vue'
import ModalTariffTypeEdit from '@/views/component/Modal/ModalTariffType/ModalTariffTypeEdit.vue'
export default {
    components: {
        TableTariffType,
        ModalTariffType,
        ModalTariffTypeEdit,
    },
    data(){
        return {
            allowances: [],
            carCategories: [],
            category_tariffs: [],
            divisions: [],
            items: [],
            showPreloader: false,
            idEditTemplate: null,
            fields: [ 
                { key: 'checkbox', label: '' },
                { key: 'id', label: 'ID', sortable: true },
                { key: 'name', label: 'Имя', sortable: true },
                { key: 'division', label: 'Подразделение', sortable: true },
                { key: 'code_doc', label: 'Код', sortable: true },
                { key: 'description_app', label: 'Описание в приложении', sortable: true },
                { key: 'client_hint', label: 'Подсказка для клиента', sortable: true },
                { key: 'dop_info', label: 'Доп. инфо', sortable: true },
                { key: 'created_by_login', label: 'Создатель', sortable: true },
                { key: 'date_time', label: 'Создано', sortable: true },
            ],
        }
    },
    mounted(){
        this.getTariffTypeData()
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        editForm(id){
            this.idEditTemplate = id
            this.openModal()
        },
        creatTemplate() {
            this.$bvModal.show(this.$route.name + 'CreateModal')
        },
        getTariffTypeData() {
            this.$http.get('tariff/types/data')
            .then(res => {
                this.carCategories = res.data.car_categories
                this.divisions = res.data.divisions
                this.allowances = res.data.allowances
                this.category_tariffs = res.data.category_tariffs
            })
        },
        openModal(){
            this.$bvModal.show('EditTemplate')
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                    this.showPreloader = true
                    this.$http
                        .get(`${this.$route.name}`, {params: param})
                        .then(res => {
                            this.items = res.data
                            this.$store.commit('pageData/setdataCount', this.items.length)
                            this.showPreloader = false
                            this.$store.commit('REFRESH_DATA', false)
                        })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                    this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        refresh(){
            this.showPreloader = true
            const params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            this.$http
                .get(`${this.$route.name}`,{ params })
                .then(res => {
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        sendToParent(tableData){
            this.items = tableData
            this.$store.commit('pageData/setdataCount', this.items.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>