<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="paternCard m-0">
                <b-table 
                striped
                small
                id="paternTable"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="items"
                :fields="fields"
                @row-dblclicked="editForm"
                @row-contextmenu="handleContextMenuEvent"
                @row-clicked="clicked"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>
                <!-- <template #cell(validity_periods)="data">
                        <span 
                        class="reference-field"
                        select-variant="secondary"
                        >
                        (***)
                        </span>
                </template> -->
                <!-- <template #cell(sentings)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template>
                <template #cell(allowance)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template>
                <template #cell(district_surcharge)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template>
                <template #cell(class_car)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template>
                <template #cell(route)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template>
                <template #cell(history_version)="data">
                    <span 
                    class="reference-field" 
                    >
                        ***
                    </span>
                </template> -->
                <template #cell(created_at)="data">
                    <span 
                    >
                        {{ data.item.created_at }}
                    </span>
                </template>
                <!-- <template #cell(audit)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template>
                <template #cell(round)="data">
                    <span 
                    class="reference-field" 
                    >
                        (***)
                    </span>
                </template> -->
                <template #cell(app_show)="data">
                    {{ data.item.app_show == 1 ? 'Да' : 'Нет' }}
                </template>
                <template #cell(site_show)="data">
                    {{ data.item.site_show == 1 ? 'Да' : 'Нет' }}
                </template>
                <template #cell(allowances)="data">
                    <span v-for="item in data.item.allowances" :key="item.id">
                        {{item.name+', '}}
                    </span>
                </template>
                <template #cell(created_by)="data">
                    {{ data.item.created_by.login }}
                </template>
            </b-table>
            <vue-context ref="menu">
                    <li>
                        <b-link
                        class="d-flex align-items-center"
                        @click="creatTemplate"
                        >
                        <feather-icon
                            icon="PlusIcon"
                            size="16"
                        />
                        <span class="ml-75">Добавить</span>
                        </b-link>
                    </li>
                </vue-context>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['items'],
    components: {
        VueContext
    },
    data(){
        return {
            fields: [
                { key: 'id', label: 'Порядок', sortable: true, thStyle: {width: '50px'} },
                { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
                { key: 'code', label: 'Код', sortable: true, thStyle: {width: '50px'}},
                // { key: 'validity_periods', label: 'Периоды действия', sortable: true, thStyle: {width: '50px'}},
                // { key: 'sentings', label: 'Настройки', sortable: true, thStyle: {width: '50px'}},
                // { key: 'allowance', label: 'Надбавка', sortable: true, thStyle: {width: '50px'}},
                // { key: 'district_surcharge', label: 'Наценка на районы', sortable: true, thStyle: {width: '50px'}},
                // { key: 'class_car', label: 'Классы авто', sortable: true, thStyle: {width: '50px'}},
                // { key: 'route', label: 'Маршруты', sortable: true, thStyle: {width: '50px'}},
                // { key: 'history_version', label: 'История версий', sortable: true, thStyle: {width: '50px'}},
                { key: 'created_at', label: 'Изменения(время подразделения)', sortable: true, thStyle: {width: '150px'}},
                // { key: 'audit', label: 'Аудит', sortable: true, thStyle: {width: '50px'}},
                // { key: 'round', label: 'Октруглять д...', sortable: true, thStyle: {width: '50px'}},
                { key: 'app_show', label: 'Показать в приложении', sortable: true, thStyle: {width: '150px'}},
                { key: 'site_show', label: 'Показать на сайте', sortable: true, thStyle: {width: '150px'}},
                { key: 'allowances', label: 'Надбавки', sortable: true, thStyle: {width: '150px'}},
                { key: 'created_by', label: 'Создал', sortable: true, thStyle: {width: '150px'}},
                { key: 'category_car', label: 'Категория авто', sortable: true, thStyle: {width: '150px'}},
                { key: 'category_tariff', label: 'Категория тарифы', sortable: true, thStyle: {width: '150px'}},
                { key: 'dop_info', label: 'Доп. инфо', sortable: true, thStyle: {width: '150px'}},
                { key: 'description_app', label: 'Описание для приложения', sortable: true, thStyle: {width: '150px'}},
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'}},
                { key: 'client_hint', label: 'Подсказка для клиента', sortable: true, thStyle: {width: '150px'}},
            ],
            id: null,
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(id){
            this.id = id.id
            this.$emit('editForm', this.id)
        },
        handleContextMenuEvent(item, index, event){
           event.preventDefault();
           this.$refs.menu.open(event)
           this.id = item.id
        },
        creatTemplate() {
            this.$emit('creatTemplate')
        },
        clicked(item, index, event){
            this.id = item.id
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;

            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.paternCard .card-body{
    padding: 0px;
}
.paternCard{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .paternCard{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }     
</style>